<template>
  <div class="pipa-video-player">
    <VueVideoPlayer
      v-if="visible"
      ref="videoPlayer"
      :options="playerConfig"
      @play="handlePlay"
      @ready="handleReady"
    />
  </div>
</template>

<script>
import { videoPlayer as VueVideoPlayer, videojs } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  name: 'VideoPlayer',
  components: { VueVideoPlayer },
  props: {
    // 播放地址
    uri: {
      type: String,
      required: false
    },
    // 清晰度{ UHD: '', HD: '', STD: '', SMD: '' }
    qualities: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    // 默认清晰度
    quality: {
      type: String,
      default: 'STD'
    },
    // 是否展示控制按钮
    controls: {
      type: Boolean,
      default: true
    },
    // 是否静音
    muted: {
      default: false
    },
    // 是否自动播放
    autoplay: {
      default: true
    },
    // 是否循环播放
    loop: {
      default: false
    },
    // 宽度
    width: {
    },
    // 高度
    height: {
    }
  },
  data () {
    // 默认清晰度处理
    let currentQualityLevel = this.quality
    if (this.qualities[currentQualityLevel] == null || this.qualities[currentQualityLevel] === '') {
      currentQualityLevel = 'UHD'
    }
    // 清晰度视频源处理
    const qualityList = [
      { label: '原画', level: 'UHD', uri: '', disabled: false },
      { label: '高清', level: 'HD', uri: '', disabled: false },
      { label: '标清', level: 'STD', uri: '', disabled: false },
      { label: '流畅', level: 'SMD', uri: '', disabled: false }
    ]
    for (const quality of qualityList) {
      quality.uri = this.qualities[quality.level]
      quality.disabled = quality.uri == null || quality.uri === ''
    }
    return {
      visible: true,
      currentTime: null,
      // 当前清晰度
      currentQualityLevel,
      // 清晰度列表
      qualityList,
      // 播放器配置
      playerConfig: {
        // 自动播放
        autoplay: this.autoplay,
        // 静音
        muted: this.muted,
        // 宽度
        width: this.width,
        // 高度
        height: this.height,
        // 语言
        language: 'zh',
        // 是否循环播放
        loop: this.loop,
        // 视频自适应屏幕
        fluid: true,
        // 视频源
        sources: [{
          type: 'video/mp4',
          src: this.uri || this.qualities[currentQualityLevel]
        }],
        // 是否需控制条
        controls: this.controls,
        // 控制条
        controlBar: {
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          // 展示剩余时间
          remainingTimeDisplay: false,
          // 声音控制
          volumePanel: {
            // 是否横向控制声音
            inline: true
          },
          children: [
            {
              name: 'playToggle'
            },
            {
              name: 'volumePanel'
            },
            {
              name: 'currentTimeDisplay'
            },
            // 进度条
            {
              name: 'progressControl'
            },
            {
              name: 'durationDisplay'
            },
            // 播放速度
            // {
            //   name: 'playbackRateMenuButton',
            //   playbackRates: [0.5, 1, 1.5, 2]
            // },
            {
              name: 'QualityMenuButton'
            },
            {
              name: 'FullscreenToggle'
            }
          ]
        }
      }
    }
  },
  methods: {
    // 初始化控制条
    initControlBar () {
      if (!this.controls) {
        return
      }
      const vm = this
      // 菜单项
      const MenuItem = videojs.getComponent('MenuItem')
      const QualityMenuItem = videojs.extend(MenuItem, {
        constructor (player, options) {
          this.quality = options
          MenuItem.apply(this, arguments)
          videojs.emptyEl(this.el())
          videojs.appendContent(this.el(), this.quality.label)
          if (this.quality.disabled) {
            this.addClass('pipa-quality-menu-item__disabled')
          }
          if (this.quality.selected) {
            this.addClass('pipa-quality-menu-item__selected')
          }
        },
        // 切换清晰度
        handleClick () {
          // 被禁用的清晰度（无资源）
          if (this.quality.disabled) {
            return
          }
          // 点击的是当前画质，视频不做重新加载
          if (vm.currentQualityLevel === this.quality.level) {
            return
          }
          // 记录当前时间
          vm.currentTime = vm.$refs.videoPlayer.player.currentTime()
          // 记录当前清晰度
          vm.currentQualityLevel = this.quality.level
          // 切换
          vm.changeQuality()
        }
      })
      videojs.registerComponent('QualityMenuItem', QualityMenuItem)
      // 菜单
      const Menu = videojs.getComponent('Menu')
      const QualityMenu = videojs.extend(Menu, {
        constructor (player, options) {
          Menu.apply(this, arguments)
          for (const quality of vm.qualityList) {
            this.addChild('QualityMenuItem', { ...quality, selected: vm.currentQualityLevel === quality.level })
          }
          this.addClass('pipa-quality-menu')
        }
      })
      videojs.registerComponent('QualityMenu', QualityMenu)
      // 按钮
      const Button = videojs.getComponent('Button')
      const QualityMenuButton = videojs.extend(Button, {
        constructor (player, options) {
          Button.apply(this, arguments)
          videojs.emptyEl(this.el())
          videojs.appendContent(this.el(), vm.qualityList.find(item => item.level === vm.currentQualityLevel).label)
          this.addChild('QualityMenu')
          // 添加该class后，点击出现菜单
          this.addClass('vjs-menu-button-popup')
          this.addClass('pipa-quality-button')
        }
      })
      videojs.registerComponent('QualityMenuButton', QualityMenuButton)
      this.$nextTick(() => {
        // 添加到controlBar中
        this.$refs.videoPlayer.player && this.$refs.videoPlayer.player.getChild('ControlBar').addChild('QualityMenuButton')
      })
    },
    // 切换清晰度
    changeQuality () {
      // 动态更换视频源
      const quality = this.qualityList.find(item => item.level === this.currentQualityLevel)
      this.playerConfig.sources[0].src = quality.uri
      this.visible = false
      this.$nextTick(() => {
        this.visible = true
      })
    },
    // 开始播放处理
    handlePlay () {
      console.log('记录播放数')
    },
    // 开始播放处理
    handleReady () {
      if (this.currentTime !== null) {
        this.$refs.videoPlayer.player.currentTime(this.currentTime)
      }
    }
  },
  mounted () {
    this.initControlBar()
  },
  destroyed () {
    // this.$refs.videoPlayer.player.dispose()
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.pipa-video-player {
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  .video-player {
    height: 100%;
  }
  /deep/ .video-js {
    height: 100%;
    padding: 0;
    // 控制条
    .vjs-control-bar {
      background-color: transparent;
      font-size: 14px;
    }
    // 进度条底色
    .vjs-progress-holder {
      height: 5px;
    }
    // 进度圆球
    .vjs-play-progress:before {
      font-size: 20px;
    }
    // 开始播放按钮
    .vjs-big-play-button {
      $size: 70px;
      width: $size;
      height: $size;
      border: 0;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -$size/2;
      margin-top: -$size/2;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, .3);
      transition: all ease .3s;
      &:hover {
        background-color: rgba(0, 0, 0, .4);
      }
      .vjs-icon-placeholder {
        &::before {
          $size: 45px;
          position: absolute;
          font-size: $size;
          display: initial;
          left: 50%;
          top: 50%;
          width: initial;
          height: initial;
          margin-left: -$size/2;
          margin-top: -$size/2;
        }
      }
    }
    // 播放中
    &.vjs-playing {
      .vjs-big-play-button {
        opacity: 0;
      }
    }
    // 清晰度按钮
    .pipa-quality-button {
      font-size: 12px;
      text-shadow: none;
    }
    // 清晰度菜单
    .pipa-quality-menu {
      width: 80px;
      border-radius: 5px;
      bottom: -6px;
      left: -16px;
      ul {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, .5);
      }
      li {
        padding: 10px 10px;
        display: block;
        text-shadow: none;
        transition: all ease .15s;
      }
      // 被禁用的选项
      .pipa-quality-menu-item__disabled {
        color: #999;
        &:hover {
          background-color: transparent;
        }
        &:focus {
          background-color: transparent;
        }
      }
      // 选中的菜单
      .pipa-quality-menu-item__selected {
        color: $primary-color;
      }
    }
  }
}
</style>
